import React from 'react';
import { useIntl } from "gatsby-plugin-intl";
import {
  Grid,
  makeStyles,
} from '@material-ui/core';
import imgStar from '../../images/icon_star.png';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '80px',
  },
  items: {
    display: 'flex',
    marginBottom: "50px",
  },
  left: {
    marginTop: '0',
    width: '40px',
    height: '40px',
  },
  right: {
    paddingLeft: '24px',
  },
  title: {
    fontSize: '24px',
    margin: 0,
  },
  desc: {
    fontSize: '20px',
    margin: '8px 0',
  }
}));

const WhyJoinUsList = ({ data }) => {
  const classes = useStyles();
  const intl = useIntl();

  const renderList = data.map((item, index) => {
    return (
      <Grid container item xs={12} md={4} key={index}>
        <div className={classes.items}>
          <img className={classes.left} src={imgStar} alt="" />
          <div className={classes.right}>
            <h4 className={classes.title}>{intl.formatMessage({ id: item.title })}</h4>
            <p className={classes.desc}>{intl.formatMessage({ id: item.desc })}</p>
          </div>
        </div>
      </Grid>
    )
  })
  
  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        {renderList}
      </Grid>
    </div>
  )
}

export default WhyJoinUsList;