import React from 'react';
import { useIntl } from "gatsby-plugin-intl";
import { HeaderTitle, HeaderTitleDesc } from '../Typography';
import WhyJoinUsList from './WhyJoinUsList';
import { WhyJoinUsData } from '../../data/page.data';

const WhyJoinUs = () => {
  const intl = useIntl();

  return (
    <>
      <HeaderTitle to="/careers" title={intl.formatMessage({ id: "careers.why_join_us" })} align="center" />
      <HeaderTitleDesc title={intl.formatMessage({ id: "careers.why_join_us_desc" })} align="center" />

      <WhyJoinUsList data={WhyJoinUsData.data} />
    </>
  )
}

export default WhyJoinUs;