import React from 'react';
import { HeaderTitle } from '../../components/Typography';
import { makeStyles, Grid, Container } from '@material-ui/core';
import { useIntl } from "gatsby-plugin-intl";
// import { useStaticQuery, graphql } from "gatsby";
import Button from '@material-ui/core/Button';
import { APP_THEME } from '../../types/theme';
import { AniLink } from '../../components/TransitionLink';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginBottom: '60px',
  },
  hiring: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '24px',
    padding: '16px',
    border: `1px solid ${theme.palette.type === APP_THEME.LIGHT ? '#cceefd' : '#0c4152'}`,
    borderRadius: '6px',
    boxShadow: '0px 4px 6px 0px rgb(60 28 148 / 3%)',

    '&:first-child': {
      marginTop: '60px',
    },

    [theme.breakpoints.down('sm')]: {
      alignItems: 'initial',
      flexDirection: 'column',
    },
  },
  header: {
    minWidth: '60%',
  },
  title: {
    fontSize: '1.6em',
    fontWeight: '600',
    wordBreak: 'break-word',
    margin: '8px 0',
  },
  type: {
    textTransform: 'uppercase',
  },
  salary: {
    color: '#ff9500',
    fontWeight: '600',
    fontSize: '1.2em',

    [theme.breakpoints.down('sm')]: {
      margin: '8px 0 16px',
    },
  },
  button: {
    border: '1px solid #009FD4',
    transition: 'all .3s ease-in-out',

    '& a': {
      color: '#009FD4',
      textDecoration: 'none',
    },

    '&:hover': {
      borderColor: '#009FD4',
      backgroundColor: '#009FD4',
      '& a': {
        color: '#fff',
      }
    }
  },
}));

const HiringCareer = () => {
  const intl = useIntl();
  const classes = useStyles();

  // const data = useStaticQuery(graphql`
  //   query HiringListQuery {
  //     allStrapiHiring {
  //       edges {
  //         node {
  //           salary
  //           slug
  //           title
  //           type
  //           date
  //         }
  //       }
  //     }
  //   }
  // `)

  const data = [
    {
      title: 'Hardware Design Engineer',
      type: 'FULLTIME'
    },
    {
      title: 'C/C++ Firmware Developer',
      type: 'FULLTIME'
    },
    {
      title: 'Software Engineer(Go, PHP, Java) - interns are also welcome',
      type: 'FULLTIME'
    },
    {
      title: 'Front-end Engineer(ReactJS)',
      type: 'FULLTIME'
    }
  ]

  return (
    <Container maxWidth="lg" className={classes.root}>
      <HeaderTitle to="/" title={intl.formatMessage({ id: "careers.job_position" })} align="center" />
      <Grid container alignItems="center" justify="center">
        {
          data.map((item, index) => {
            return (
              <div className={classes.hiring} key={index}>
                <div className={classes.header}>
                  <h3 className={classes.title}>{item.title}</h3>
                  <span className={classes.type}>{item.type}</span>
                </div>
                {/* <div className={classes.salary}>{data.node.salary}</div> */}
                
                  <Button variant="outlined" color="primary" className={classes.button}>
                    <AniLink to="/contact-us">
                      {intl.formatMessage({ id: "careers.apply" })} 
                    </AniLink>
                  </Button>
                
              </div>
            )
          })
        }

        {/* <div className={classes.hiring}>
          <div className={classes.header}>
            <h3 className={classes.title}>[Junior/Senior] Mobile Developer (Android/iOS)</h3>
            <span className={classes.type}>FULLTIME</span>
          </div>
          <div className={classes.salary}>{data.node.salary}</div>
          <Button variant="outlined" color="primary" className={classes.button}>
            {intl.formatMessage({ id: "careers.apply" })} 
          </Button>
        </div> */}
      </Grid>
    </Container>
  )
}

export default HiringCareer;