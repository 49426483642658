export const WhyJoinUsData = {
  data: [
    {
      "title": "fast_growing_company",
      "desc": "fast_growing_company_desc"
    },
    {
      "title": "great_colleagues",
      "desc": "great_colleagues_desc"
    },
    {
      "title": "take_charge",
      "desc": "take_charge_desc"
    },
    {
      "title": "dont_stop_learning",
      "desc": "dont_stop_learning_desc"
    },
    {
      "title": "latest_technology",
      "desc": "latest_technology_desc"
    },
    {
      "title": "cross_domain_exposure",
      "desc": "cross_domain_exposure_desc"
    }
  ]
}