import React from 'react';
import { useIntl } from "gatsby-plugin-intl";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image"
import { HeaderTitle, HeaderTitleDesc } from '../../components/Typography';

const WorkingTogether = () => {
  const intl = useIntl();

  const data = useStaticQuery(graphql`
  query {
    placeholderImage: file(relativePath: { eq: "team.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)

  return (
    <>
      <HeaderTitle to="/careers" title={intl.formatMessage({ id: "careers.working_together" })} align="center" />
      <HeaderTitleDesc title={intl.formatMessage({ id: "careers.working_together_desc" })} align="center" />
      <Img fluid={data.placeholderImage.childImageSharp.fluid} />
    </>
  )
}

export default WorkingTogether;