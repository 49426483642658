import React from "react"
import { useIntl } from "gatsby-plugin-intl";
import {
  Container,
  makeStyles,
} from '@material-ui/core';
import Layout from "../layouts"
import SEO from "../layouts/seo";
import WhyJoinUs from '../components/WhyJoinUs';
import WorkingTogether from '../components/WorkingTogether';
import HiringCareer from '../components/Hiring/HiringCareer';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingTop: '160px',
    marginBottom: '60px',
  },
}));


const CareersPage = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: "navigation.careers" })}
      />
      <Container maxWidth="lg" className={classes.root}>
        <HiringCareer />
        <WorkingTogether />
        <WhyJoinUs />
      </Container>
    </Layout>
  )
}
export default CareersPage

